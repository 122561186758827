<template>
  <div>
    <c-search-box>
      <template slot="search">
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <c-plant type="none" name="plantCd" v-model="searchParam.plantCd" @input="getList" />
        </div>
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <c-datepicker
            type="year"
            :range="true"
            defaultStart="-1y"
            defaultEnd="0y"
            label="감사년도"
            name="selfInspectionYear"
            v-model="selfInspectionYear"
            @input="getList"
          />
        </div>
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <c-select
            codeGroupCd="SELF_INSPECTION_TYPE_CD"
            type="none"
            itemText="codeName"
            itemValue="code"
            name="selfInspectionTypeCd"
            label="자체감사종류"
            v-model="searchParam.selfInspectionTypeCd"
            @input="getList"
          ></c-select>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <c-select
            type="none"
            :comboItems="revisionItems"
            itemText="codeName"
            itemValue="code"
            label="평가항목 개정번호"
            name="sysRevision"
            v-model="searchParam.sysRevision"
            @input="getList">
          </c-select>
        </div>
      </template>
    </c-search-box>
    <div class="row">
      <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
        <c-table
          ref="table"
          title="자체감사결과별 실배점"
          tableId="table"
          :columnSetting="false"
          :usePaging="false"
          :filtering="false"
          :hideBottom="true"
          :columns="grid.columns"
          :data="grid.data"
        >
        </c-table>
      </div>
      <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6" style="background:#fff;">
        <apexchart 
          ref="chart1" 
          type="radar"
          height="600" 
          :options="chart.chartOptions" 
          :series="chart.series"></apexchart>
      </div>
      </div>
  </div>
</template>

<script>
import selectConfig from '@/js/selectConfig';
import VueApexCharts from "vue-apexcharts";
export default {
  name: "compliance",
  components: {
    apexchart: VueApexCharts
  },
  data() {
    return {
      revisionItems: [],
      grid: {
        columns: [],
        data: [],
      },
      columnset: [
        {
          name: 'itemMstName',
          field: 'itemMstName',
          label: '항목',
          align: 'left',
          sortable: false
        },
        {
          name: 'maxChangeScoring',
          field: 'maxChangeScoring',
          label: '최고환산점수',
          align: 'center',
          style: 'width:100px',
          sortable: false
        },
      ],
      searchParam: {
        plantCd: null,
        startYear: null,
        endYear: null,
        sysRevision: 1,
        selfInspectionTypeCd: 'SITC000001', // 정기자체감사
      },
      selfInspectionYear: [],
      editable: true,
      listUrl: '',
      revisionUrl: '',
      chart: {
        chartOptions: {
         // 그래프 설정
          chart: {
            height: 350,
            type: 'radar',
            dropShadow: {
              enabled: true,
              color: '#000',
              top: 18,
              left: 7,
              blur: 10,
              opacity: 0.2
            },
            toolbar: {
              show: true
            },
            zoom: {
              enabled: false
            },
          },
          plotOptions: {
            radar: {
              size: 250,
              polygons: {
                strokeColors: '#e9e9e9',
                fill: {
                  colors: ['#f8f8f8', '#fff']
                }
              }
            }
          },
          legend: {
            position: 'bottom',
            horizontalAlign: 'center',
          },
          title: {
            text: '자체감사결과 추이분석'
          },
          stroke: {
            width: 2
          },
          fill: {
            opacity: 0.1
          },
          markers: {
            size: 5,
            hover: {
              size: 10
            },
            discrete: []
          },
          xaxis: {
            categories:[]
          },
          yaxis: {
            min: 0,
            max: 100,
            tickAmount: 5,
          },
          tooltip: {
            enabled: false,
          }
        },
        series: [],
      }
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {},
  methods: {
    init() {
      this.editable = this.$route.meta.editable;
      this.listUrl = selectConfig.sop.pca.result.graph.list.url;
      this.revisionUrl = selectConfig.sop.pca.item.revs.item;
      this.getRevision();
    },
    getRevision() {
      this.$http.url = this.revisionUrl;
      this.$http.type = 'GET';
      this.$http.request((_result) => {
        this.revisionItems = _result.data;
        this.searchParam.sysRevision = this.revisionItems[0].code;
        this.getList();
      },);
    },
    getList() {
      if (this.selfInspectionYear.length === 2) {
        this.searchParam.startYear = this.selfInspectionYear[0];
        this.searchParam.endYear = this.selfInspectionYear[1];
      } else {
        this.searchParam.startYear = this.$comm.getPrevYear()-1;
        this.searchParam.endYear = this.$comm.getThisYear();
      }
      this.$http.url = this.listUrl;
      this.$http.type = 'GET';
      this.$http.param = this.searchParam;
      this.$http.request((_result) => {
        if (_result.data.info.length > 0) {
          this.chart.chartOptions.xaxis.categories = [];
          this.chart.chartOptions.markers.discrete = [];
          this.chart.series = [];
          this.grid.data = [];
          this.grid.columns = this.columnset;

          let mstdata = this.$_.filter(_result.data.list, {dataTypes : '0' });
          let cate = [];
          this.$_.forEach(mstdata, _item => {
            cate.push(_item.itemMstName)
          });
          let j = 0;
          this.$_.forEach(_result.data.info, item1 => {
            this.grid.columns = this.$_.concat(this.grid.columns,{
              label: item1.selfInspectionTitle,
              align: 'center',
              child: [
                {
                  name: item1.selfInspectionResultId,
                  field: item1.selfInspectionResultId,
                  label: '환산점수',
                  align: 'center',
                  style: 'width:80px',
                  sortable: false
                },
                {
                  name: item1.selfInspectionResultId2,
                  field: item1.selfInspectionResultId2,
                  label: '백분위점수',
                  align: 'center',
                  style: 'width:80px',
                  sortable: false
                },
              ]
            });
            let title = item1.selfInspectionTitle;
            let serieses = [];
            this.$_.forEach(_result.data.list, item2 => {
              if (item1.selfInspectionResultId == item2.selfInspectionResultId) {
                serieses.push(item2.realScore);
              }
            })
            var minvalue = Math.min.apply(null, serieses);
            let i = 0;
            this.$_.forEach(serieses, item3 => {
              if (minvalue == item3) {
                this.chart.chartOptions.markers.discrete.push({
                    seriesIndex: j,
                    dataPointIndex: i,
                    fillColor: 'red',
                    strokeColor: '#FFF',
                    size: 6
                  }
                )
              }
              i++;
            })
            this.chart.series.push({
              name: title,
              data: serieses
            })
            j++;
          })
          this.grid.data = _result.data.items;
          this.chart.chartOptions.xaxis.categories = cate;

          this.$refs['chart1'].refresh();
        } else {
          this.chart.chartOptions.xaxis.categories = [];
          this.chart.chartOptions.markers.discrete = [];
          this.chart.series = [];
          this.grid.columns = [];
          this.grid.data = [];
          this.$refs['chart1'].refresh();
        }
      },);
    },
  },
};
</script>
